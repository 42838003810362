.largeScreen {
  /*订单总量滚动数字设置*/
  /* 默认逗号设置 */
  /*滚动数字设置*/
}
.largeScreen .box-item {
  position: relative;
  font-size: 54px;
  line-height: 3.8rem;
  text-align: center;
  list-style: none;
  writing-mode: vertical-lr;
  text-orientation: upright;
  /*文字禁止编辑*/
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
  /* overflow: hidden; */
}
.largeScreen .mark-item {
  width: 10px;
  height: 100px;
  margin-right: 5px;
  line-height: 10px;
  font-size: 48px;
  position: relative;
}
.largeScreen .mark-item > span {
  position: absolute;
  width: 100%;
  bottom: 0;
  writing-mode: vertical-rl;
  text-orientation: upright;
}
.largeScreen .number-item:last-child {
  margin-right: 0;
}
.largeScreen .number-item {
  width: 2.8rem;
  height: 3.8rem;
  background: #ccc;
  list-style: none;
  margin-right: 5px;
  border-radius: 4px;
  background: url(../../assets/number-bg.png) no-repeat center center;
  background-size: 100% 100%;
}
.largeScreen .number-item > span {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 100%;
  height: 100%;
  writing-mode: vertical-rl;
  text-orientation: upright;
  overflow: hidden;
}
.largeScreen .number-item > span > i {
  font-style: normal;
  position: absolute;
  top: 0;
  left: 50%;
  font-weight: 600;
  text-shadow: 0px 0px 13px rgba(7, 6, 28, 0.52);
  color: #52ffff;
  transform: translate(-50%, 0);
  transition: transform 1s ease-in-out;
  letter-spacing: 10px;
}
.largeScreen .el-dialog {
  background: transparent;
}
.largeScreen .el-dialog__header {
  display: none;
}
.largeScreen .dialog2 .el-dialog__body {
  background-image: url("../../assets/dialogbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0;
}
.largeScreen .studentspan {
  width: 4rem;
}
.largeScreen .dialogpdf .el-dialog__body {
  background-image: url("../../assets/dialogbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 40px 20px 34px;
}
