


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































header {
  height: 9%;
  color: #fff;
}
.header {
  display: flex;
  align-items: center;
  height: 100%;
  .weekBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h2 {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  }
}
.sectionBox {
  display: flex;
  height: 91%;
  .largebox {
    width: 50%;
    height: 100%;
    .largeLeft {
      width: 100%;
      height: 100%;
      padding: 0.5rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .largeLeft_container {
        background-image: url("../../assets/borderbg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 50%;
        box-sizing: border-box;
        padding: 0.25rem;
        .largeLeft_container_border {
          // border: 0.2rem solid #3e3e3e;

          height: 100%;
          .largeLeftPiebox {
            width: 100%;
            height: 100%;
            display: flex;
            .largepie {
              width: 100%;
              height: 100%;
            }
          }
          .largeRight_container {
            width: 100%;
            height: 100%;
            .largeRight_container_border {
              width: 50%;
              height: 100%;
              border: 0.2rem solid red;
              .largeRight_container_studentNumBox {
                display: flex;
                .largeRight_container_studentNum {
                  width: 100%;
                  padding: 0.2rem;
                  > div {
                    border: 0.05rem solid #ccc;
                  }
                }
              }
              .largeRight_container_piechartBox {
                height: 80%;
                display: flex;
                flex-direction: column;
                .largeRight_container_piechart {
                  height: 100%;
                }
              }
            }
          }
          .largeLeft_container_center {
            flex-wrap: wrap;
            > .largeCenter_container_num {
              width: 33.3%;
              > div {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
              }
              p {
                text-align: center;
              }
              i {
                width: 2rem;
                height: 3rem;
                border: 1px solid red;
                font-size: 24px;
                line-height: 2;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0.2rem;
              }
              em {
                min-width: 4rem;
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
}
.ulTitle {
  font-size: 1rem;
  // padding: 0 2.35rem;
  li {
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
    span {
      text-align: center;
      flex: 1;
      padding: 0.5rem 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 100%;
    }
  }
  .projectNum {
    span {
      color: #52ffff;
    }
  }
}
.ulcontent {
  font-size: 0.9rem;
}
.projectNumcontent {
  font-size: 0.8rem;
  color: #ffffff;
}
.el-carousel {
  padding: 0 1rem;
}
.seamless-warp {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 0 1rem 0;
}
.studentBox {
  display: flex;
  padding-top: 2rem;
}
.el-form-item {
  width: 30%;
  margin-bottom: 0;
  height: 1.5rem;
  p {
    color: #fff;
  }
}
.on {
  background: transparent;
}
.off {
  background: #141e38;
}
.pageContol {
}
.bgc {
  background-image: url("../../assets/BG.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.titleH3 {
  padding-top: 1.3rem;
  font-weight: bold;
  background: linear-gradient(0deg, #76c0ff 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.goodtop {
  display: flex;
  .imgBoxs {
    width: 33%;
    position: relative;
    img {
      width: 100%;
    }
    div {
      position: absolute;
      top: 22%;
      left: 48%;
      p {
        color: #fff;
        font-size: 0.9rem;
      }
      p:nth-child(2) {
        font-size: 0.7rem;
      }
    }
  }
}
.btns {
  padding-top: 1.3rem;
  display: flex;
  span {
    margin-right: 0.75rem;
    width: 5rem;
    height: 2rem;
    color: #ccc;
    display: flex;
    background: #000000;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .btnbg {
    color: #fff;
    background: rgba(8, 9, 36, 0);
    box-shadow: 0px 0px 88px 7px rgba(16, 108, 222, 0.22),
      0px 0px 11px 3px rgba(0, 114, 255, 0.8);
    border-radius: 10px;
  }
}
.listul {
  flex-wrap: wrap;
  li {
    width: 33.3%;
    color: #fff;
  }
}
